.bouncing-logo {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bouncing-logo img {
    width: 100%;
    height: auto;
   
  }
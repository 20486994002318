.slider-content{
    display:grid;
    
  };
.img-slider{
    width: 600px;
    
}
.focus-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.focus-area.active {
  opacity: 0.5;
}
.fullscreen-black{
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 999;
}
